import React from "react";
import { useMediaQuery } from "./Features";
function Header() {
	const isLargerThanMd = useMediaQuery("(min-width: 768px)");

	return (
		<>
			<h1
				style={{
					fontSize: isLargerThanMd ? "2.5em" : "2.1em",
					fontFamily: "Virgil",
					// color: "black",

					// fontDisplay:"swap"
					// src: url("Virgil.woff2"),
				}}
				className={`text-center font-semibold w-full mb-6 ${
					isLargerThanMd ? "pt-2" : "mt-2"
				}`}>
				BLUR THINGS
			</h1>
		</>
	);
}

export default Header;
