import React, { useEffect, useRef, useState } from "react";
import { useCanvasDataContext } from "../util/CanvasDataContext";
import { blurFn } from "../util/helpers";
import { useToggleSwitchContext } from "../util/ToggleSwitchContext";
import Switch from "./Switch";

interface Props {
	canvasRef2: React.RefObject<HTMLCanvasElement>;
	copyCanvas: () => void;
	recognizing: boolean;
}

const BlurOptions: React.FC<Props> = ({
	canvasRef2,
	copyCanvas,
	recognizing,
}) => {
	const { blurText, blurFace, setBlurText, setBlurFace } =
		useToggleSwitchContext();
	const [textBlurRadius, setTextBlurRadius] = useState(50);
	const [faceBlurRadius, setFaceBlurRadius] = useState(50);
	const { canvasObjects } = useCanvasDataContext();

	const waitForRecognitionToEnd = () => {
		return new Promise((resolve) => {
			const interval = setInterval(() => {
				if (!recognizing) {
					clearInterval(interval);
					resolve(true);
					console.log("Interval running");
				}
			}, 100);
		});
	};

	const applyBlurEffect = async () => {
		console.log("Blur Effect Running");
		if (!canvasRef2.current) return;
		copyCanvas();
		await waitForRecognitionToEnd();
		if (canvasObjects && blurText && canvasRef2.current) {
			blurFn(canvasObjects, textBlurRadius, canvasRef2.current, "TEXT");
			console.log("Text Blurred");
		}

		if (canvasObjects && blurFace && canvasRef2.current) {
			blurFn(canvasObjects, faceBlurRadius, canvasRef2.current, "FACE");
			console.log("Face Blurred");
		}
	};

	useEffect(() => {
		applyBlurEffect();
		console.log("UseEffect of applyBlurEffect running");
	}, [blurText, blurFace, canvasObjects]);

	return (
		<div className="flex justify-evenly mt-8">
			<div className="flex items-center pl-40 pr-40 flex-row space-x-2  text-lg text-purple-100 ">
				<Switch
					id="blur-text"
					label="Blur&nbsp;Text"
					value={blurText}
					setValue={setBlurText}
					// style={{
					// 	fontFamily: "Virgil",
					// }}
					// mb="0.5rem"
				/>
				<Switch
					id="blur-face"
					label="Blur&nbsp;Face"
					value={blurFace}
					setValue={setBlurFace}
					// style={{
					// 	fontFamily: "Virgil",
					// }}
					// mb="0.5rem"
				/>
			</div>
		</div>
	);
};

export default BlurOptions;
