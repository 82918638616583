import React, { useEffect, useState } from "react";

type Props = {};

const featureList = [
	"Private and Secure: The images never leave the browser",
	"Small models are downloaded in your browser",
	"Will always be free",
	"Can blur text and face from the picture",
	"Upload an image and get started",
];

export function useMediaQuery(query: string): boolean {
	const [matches, setMatches] = useState<boolean>(
		window.matchMedia(query).matches
	);

	useEffect(() => {
		const media = window.matchMedia(query);
		const listener = (event: MediaQueryListEvent) => {
			setMatches(event.matches);
		};

		// Use addEventListener instead of addListener
		media.addEventListener("change", listener);

		return () => {
			// Use removeEventListener instead of removeListener
			media.removeEventListener("change", listener);
		};
	}, [query]);

	return matches;
}

// eslint-disable-next-line no-empty-pattern
function Features({}: Props) {
	const isLargerThanMd = useMediaQuery("(min-width: 768px)");
	return (
		// <Flex
		// 	flexDir="column"
		// 	my="12"
		// 	justifyContent="space-between"
		// 	fontSize={isLargerThanMd ? "md" : "sm"}
		// 	display="inline-block"
		// 	width={isLargerThanMd ? "75%" : "55%"}>
		// 	{featureList.map((feature) => (
		// 		<Alert
		// 			status="success"
		// 			mb="0.5"
		// 			variant="subtle"
		// 			whiteSpace={isLargerThanMd ? "nowrap" : "normal"}
		// 			display="inline-block">
		// 			<AlertIcon />
		// 			{feature}
		// 		</Alert>
		// 	))}
		// </Flex>
		<div
			className={`flex flex-col my-10 justify-between text-${
				isLargerThanMd ? "md" : "sm"
			} inline-block w-${isLargerThanMd ? "3/4" : "11/20"}`}>
			{featureList.map((feature) => (
				// eslint-disable-next-line no-template-curly-in-string
				<div
					className={`alert alert-success mb-2 inline-block whitespace-${
						isLargerThanMd ? "nowrap" : "normal"
					} `}>
					{/* Placeholder for AlertIcon */}
					<span className="alert-icon"></span>
					{feature}
				</div>
			))}
		</div>
	);
}

export default Features;
