import * as StackBlur from "stackblur-canvas";
import * as faceapi from "face-api.js";
import { fabric } from "fabric";
import { v4 } from "uuid";

export const fitImageOn = function (
  canvas: HTMLCanvasElement,
  image: any,
  ctx: CanvasRenderingContext2D
) {
  let imageAspectRatio = image.width / image.height;
  let canvasAspectRatio = canvas.width / canvas.height;
  let renderableHeight, renderableWidth, xStart, yStart;

  if (imageAspectRatio < canvasAspectRatio) {
    renderableHeight = canvas.height;
    renderableWidth = image.width * (renderableHeight / image.height);
    xStart = (canvas.width - renderableWidth) / 2;
    yStart = 0;
  } else if (imageAspectRatio > canvasAspectRatio) {
    renderableWidth = canvas.width;
    renderableHeight = image.height * (renderableWidth / image.width);
    xStart = 0;
    yStart = (canvas.height - renderableHeight) / 2;
  } else {
    renderableHeight = canvas.height;
    renderableWidth = canvas.width;
    xStart = 0;
    yStart = 0;
  }

  ctx.drawImage(image, xStart, yStart, renderableWidth, renderableHeight);
};

export const loadScript = (
  src: string,
  position: any,
  id: string,
  onLoad: () => void
) => {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  script.onload = onLoad;
  position.appendChild(script);
};

export const loadModels = async (
  setModelsLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setModelsLoaded(false);
  await faceapi.loadSsdMobilenetv1Model("/models");
  await faceapi.loadFaceLandmarkModel("/models");
  await faceapi.loadFaceRecognitionModel("/models");
  setModelsLoaded(true);
};

export const blurFn = (
  boxes: fabric.Object[],
  radius: number,
  canvas: HTMLCanvasElement,
  type: string
) => {
  const textBoxes = boxes.filter((box) => box.stroke === "blue");
  const faceBoxes = boxes.filter((box) => box.stroke === "red");

  switch (type) {
    case "TEXT":
      textBoxes.forEach((box) => {
        const w = box.scaleX ? box.width! * box.scaleX : box.width;
        const h = box.scaleY ? box.height! * box.scaleY : box.height;

        StackBlur.canvasRGBA(
          canvas,
          Math.floor(box.left!),
          Math.floor(box.top!),
          Math.floor(w!),
          Math.floor(h!),
          radius
        );
      });
      break;
    case "FACE":
      faceBoxes.forEach((box) => {
        const w = box.scaleX ? box.width! * box.scaleX : box.width;
        const h = box.scaleY ? box.height! * box.scaleY : box.height;

        StackBlur.canvasRGBA(
          canvas,
          Math.floor(box.left!),
          Math.floor(box.top!),
          Math.floor(w!),
          Math.floor(h!),
          radius
        );
      });
      break;
    default:
      return;
  }
};

export const addRect = (
  canvi: fabric.Canvas,
  left: number,
  top: number,
  w: number,
  h: number,
  stroke: string
) => {
  const name = v4();
  const rect = new fabric.Rect({
    left,
    top,
    fill: "transparent",
    width: w,
    height: h,
    name,
    objectCaching: false,
    stroke,
    strokeWidth: 1,
    hasRotatingPoint: false,
    lockRotation: true,
  });

  rect.setControlsVisibility({
    mtr: false,
  });
  canvi.add(rect);
  canvi.setActiveObject(rect);
  canvi.renderAll();

  return rect;
};

export const deleteRect = (canvi: fabric.Canvas) => {
  const activeObjects = canvi.getActiveObjects();
  canvi.remove(...canvi.getActiveObjects().concat());
  return activeObjects;
};

export const preventOverflowCanvas = (e: any) => {
  var obj = e.target;
  // if object is too big ignore
  if (
    obj.currentHeight > obj.canvas.height ||
    obj.currentWidth > obj.canvas.width
  ) {
    return;
  }
  obj.setCoords();
  // top-left  corner
  if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
    obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
    obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
  }
  // bot-right corner
  if (
    obj.getBoundingRect().top + obj.getBoundingRect().height >
      obj.canvas.height ||
    obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width
  ) {
    obj.top = Math.min(
      obj.top,
      obj.canvas.height -
        obj.getBoundingRect().height +
        obj.top -
        obj.getBoundingRect().top
    );
    obj.left = Math.min(
      obj.left,
      obj.canvas.width -
        obj.getBoundingRect().width +
        obj.left -
        obj.getBoundingRect().left
    );
  }
};

export const toDataURL = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const dataURLtoFile = (dataurl: any, filename: string) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
