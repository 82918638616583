import { faFont, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fabric } from "fabric";
import React, { useEffect, useRef, useState } from "react";
import _ from "underscore";
import { tinykeys } from "tinykeys";
import { useCanvasDataContext } from "../util/CanvasDataContext";
import { addRect, deleteRect, preventOverflowCanvas } from "../util/helpers";
import { useToggleSwitchContext } from "../util/ToggleSwitchContext";
import { useMedia } from "react-use";

interface Props {
	height: number;
	width: number;
	words: any;
	faceBox: any;
}

const Canvas: React.FC<Props> = ({ height, width, words, faceBox }) => {
	const [fabricCanvas, setFabricCanvas] = useState<fabric.Canvas>();
	const [backupObjects, setBackupObjects] = useState<fabric.Object[]>([]);

	const { setCanvasObjects } = useCanvasDataContext();
	const { blurText, blurFace, setBlurFace, setBlurText } =
		useToggleSwitchContext();
	const isLargerThan500 = useMedia("(minWidth: 500px)");

	const isMountedWords = useRef(false);
	const isMountedFaces = useRef(false);
	const deleteButtonRef = useRef<HTMLButtonElement>(null);

	// Initialize
	useEffect(() => {
		if (fabricCanvas) return;
		fabric.Object.prototype.transparentCorners = false;
		fabric.Object.prototype.cornerColor = "black";
		fabric.Object.prototype.cornerStyle = "circle";
		fabric.Object.prototype.cornerSize = 5;
		setFabricCanvas(
			new fabric.Canvas("canvas", {
				backgroundColor: "transparent",
				renderOnAddRemove: true,
				selection: false,
			})
		);
	}, [fabricCanvas]);

	// Prevent Overflow of Rectangles on Canvas
	useEffect(() => {
		if (!fabricCanvas) return;
		fabricCanvas.on("object:moving", preventOverflowCanvas);

		return () => {
			fabricCanvas.off("object:moving", preventOverflowCanvas);
		};
	}, [fabricCanvas]);

	// Dynamic Width Height
	useEffect(() => {
		if (!width || !height || !fabricCanvas) return;

		fabricCanvas.setHeight(height);
		fabricCanvas.setWidth(width);
	}, [width, height, fabricCanvas]);

	// render boxes from word recognitions (first render only)
	useEffect(() => {
		if (!words || !fabricCanvas || !blurText || isMountedWords.current) return;

		words.forEach((word: any) => {
			const w = word.x1 - word.x0;
			const h = word.y1 - word.y0;

			addRect(
				fabricCanvas,
				Math.floor(word.x0 * 1.2),
				Math.floor(word.y0 * 1.2),
				Math.floor(w * 1.2),
				Math.floor(h * 1.2),
				"blue"
			);
		});

		const rects = fabricCanvas
			.getObjects()
			.filter((box) => box.stroke === "blue");
		setBackupObjects([...backupObjects, ...rects]);
		isMountedWords.current = true;
	}, [words, fabricCanvas, blurText, backupObjects]);

	// render boxes from face recognitions (first render only)
	useEffect(() => {
		if (!faceBox || !fabricCanvas || !blurFace || isMountedFaces.current)
			return;

		faceBox.forEach((face: any) => {
			addRect(
				fabricCanvas,
				Math.floor(face.x),
				Math.floor(face.y),
				Math.floor(face.width),
				Math.floor(face.height),
				"red"
			);
		});

		const rects = fabricCanvas
			.getObjects()
			.filter((box) => box.stroke === "red");
		setBackupObjects([...backupObjects, ...rects]);

		isMountedFaces.current = true;
	}, [faceBox, fabricCanvas, blurFace, backupObjects]);

	// render boxes from backup on later renders
	useEffect(() => {
		if (!fabricCanvas) return;

		if (blurText && isMountedWords.current) {
			const textBoxes = backupObjects.filter((box) => box.stroke === "blue");
			fabricCanvas.add(...textBoxes);
		}

		if (blurFace && isMountedFaces.current) {
			const faceBoxes = backupObjects.filter((box) => box.stroke === "red");
			fabricCanvas.add(...faceBoxes);
		}
	}, [fabricCanvas, blurText, blurFace, backupObjects]);

	// remove all boxes on toggle off
	useEffect(() => {
		if (!fabricCanvas) return;

		if (!blurText) {
			const textBoxes = fabricCanvas
				.getObjects()
				.filter((box) => box.stroke === "blue");
			fabricCanvas.remove(...textBoxes.concat());
		}

		if (!blurFace) {
			const faceBoxes = fabricCanvas
				.getObjects()
				.filter((box) => box.stroke === "red");
			fabricCanvas.remove(...faceBoxes.concat());
		}
	}, [blurText, blurFace, fabricCanvas]);

	// whenever image changes or language changes
	useEffect(() => {
		if (!isMountedWords.current || !fabricCanvas) return;
		isMountedWords.current = false;

		fabricCanvas.remove(
			...fabricCanvas
				.getObjects()
				.filter((box) => box.stroke === "blue")
				.concat()
		);
		setBackupObjects(fabricCanvas.getObjects());
	}, [words, fabricCanvas]);

	// whenever image changes
	useEffect(() => {
		if (!isMountedFaces.current || !fabricCanvas) return;
		isMountedFaces.current = false;

		fabricCanvas.remove(
			...fabricCanvas
				.getObjects()
				.filter((box) => box.stroke === "red")
				.concat()
		);
		setBackupObjects(fabricCanvas.getObjects());
	}, [fabricCanvas, faceBox]);

	const updateObjects = _.throttle((e: fabric.IEvent) => {
		if (!fabricCanvas) return;
		setCanvasObjects(fabricCanvas.getObjects());
	}, 250);

	// Fabric Canvas Event Listeners
	useEffect(() => {
		if (!fabricCanvas) return;

		fabricCanvas.on("object:moving", updateObjects);
		fabricCanvas.on("object:scaling", updateObjects);
		fabricCanvas.on("object:resizing", updateObjects);
		fabricCanvas.on("object:added", updateObjects);
		fabricCanvas.on("object:removed", updateObjects);

		return () => {
			fabricCanvas.off();
		};
	}, [fabricCanvas]);

	// Delete Key useEffect
	useEffect(() => {
		if (!fabricCanvas) return;
		let unsubscribe = (tinykeys as any)(window, {
			delete: () => deleteButtonRef.current?.click(),
		});

		return () => {
			unsubscribe();
		};
	}, [fabricCanvas]);

	return (
		<>
			<canvas
				className="m-auto absolute"
				id="canvas"></canvas>
			<div className="flex justify-evenly">
				<button
					className={`my-2 focus:outline-none ${
						blurFace ? "opacity-50 cursor-not-allowed" : ""
					} bg-transparent border border-red-500 text-red-500 hover:bg-red-500 hover:text-white`}
					onClick={() => {
						const newRect = addRect(fabricCanvas!, 100, 100, 100, 100, "red");
						setBackupObjects([...backupObjects, newRect]);
						setBlurFace(!blurFace);
					}}>
					<FontAwesomeIcon icon={faUser} />
					{isLargerThan500 && " Add Face"}
				</button>
				<button
					className={`my-2 focus:outline-none ${
						blurText ? "opacity-50 cursor-not-allowed" : ""
					} bg-transparent border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white`}
					onClick={() => {
						const newObject = addRect(
							fabricCanvas!,
							100,
							100,
							100,
							100,
							"blue"
						);
						setBackupObjects([...backupObjects, newObject]);
						setBlurText(!blurText);
					}}>
					<FontAwesomeIcon icon={faFont} />
					{isLargerThan500 && " Add Text"}
				</button>
				<button
					className="my-2 focus:outline-none bg-transparent border border-cyan-500 text-cyan-500 hover:bg-cyan-500 hover:text-white"
					ref={deleteButtonRef}
					onClick={() => {
						if (!fabricCanvas) return;
						const deletedObjects = deleteRect(fabricCanvas);
						deletedObjects.forEach((object) => {
							setBackupObjects(
								backupObjects.filter((backup) => backup.name !== object.name)
							);
						});
					}}>
					<FontAwesomeIcon icon={faTrash} />
					{isLargerThan500 && " Delete"}
				</button>
			</div>
		</>
	);
};

export default Canvas;
