// import { ChevronDownIcon } from "@chakra-ui/icons";
// import {
// 	Menu,
// 	MenuButton,
// 	MenuDivider,
// 	MenuItemOption,
// 	MenuList,
// 	MenuOptionGroup,
// } from "@chakra-ui/menu";
import React, { useState } from "react";

interface Props {
	language: string;
	setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const LanguageDropdown: React.FC<Props> = ({ language, setLanguage }) => {
	const [open, setOpen] = useState(false);

	return (
		<></>
		// <Menu
		// 	closeOnSelect
		// 	isOpen={open}
		// 	placement="bottom"
		// 	preventOverflow={true}>
		// 	<MenuButton
		// 		transition="all 0.2s"
		// 		onClick={() => setOpen((prev) => !prev)}
		// 		px="2"
		// 		py="2"
		// 		m="0 auto"
		// 		display="block"
		// 		borderRadius="md"
		// 		_hover={{ bg: "gray.300" }}
		// 		_focus={{ boxShadow: "outline" }}
		// 		fontSize="sm"
		// 		color="#7e2e84">
		// 		<ChevronDownIcon /> Change Language
		// 	</MenuButton>
		// 	<MenuList
		// 		maxH="300px"
		// 		overflowY="scroll">
		// 		<MenuOptionGroup
		// 			title="Languages"
		// 			type="radio"
		// 			defaultValue={language}
		// 			onChange={(v) => {
		// 				setLanguage(v as string);
		// 				setOpen(false);
		// 			}}>
		// 			<MenuItemOption value="chi_sim"> Chinese </MenuItemOption>
		// 			<MenuItemOption value="eng">English</MenuItemOption>
		// 			<MenuItemOption value="hin"> Hindi </MenuItemOption>
		// 			<MenuItemOption value="spa"> Spanish </MenuItemOption>
		// 			<MenuItemOption value="fra"> French </MenuItemOption>
		// 			<MenuDivider />
		// 			<MenuItemOption value="afr"> Afrikaans </MenuItemOption>
		// 			<MenuItemOption value="ara"> Arabic </MenuItemOption>
		// 			<MenuItemOption value="aze"> Azerbaijani </MenuItemOption>
		// 			<MenuItemOption value="bel"> Belarusian </MenuItemOption>
		// 			<MenuItemOption value="ben"> Bengali </MenuItemOption>
		// 			<MenuItemOption value="bul"> Bulgarian </MenuItemOption>
		// 			<MenuItemOption value="cat"> Catalan </MenuItemOption>
		// 			<MenuItemOption value="ces"> Czech </MenuItemOption>
		// 			<MenuItemOption value="chi_sim"> Chinese </MenuItemOption>
		// 			<MenuItemOption value="chi_tra"> Traditional Chinese </MenuItemOption>
		// 			<MenuItemOption value="chr"> Cherokee </MenuItemOption>
		// 			<MenuItemOption value="dan"> Danish </MenuItemOption>
		// 			<MenuItemOption value="deu"> German </MenuItemOption>
		// 			<MenuItemOption value="ell"> Greek </MenuItemOption>
		// 			<MenuItemOption value="eng">English</MenuItemOption>
		// 			<MenuItemOption value="enm"> English (Old) </MenuItemOption>
		// 			<MenuItemOption value="meme"> Internet Meme </MenuItemOption>
		// 			<MenuItemOption value="epo"> Esperanto </MenuItemOption>
		// 			<MenuItemOption value="epo_alt">Esperanto alternative</MenuItemOption>
		// 			<MenuItemOption value="equ"> Math </MenuItemOption>
		// 			<MenuItemOption value="est"> Estonian </MenuItemOption>
		// 			<MenuItemOption value="eus"> Basque </MenuItemOption>
		// 			<MenuItemOption value="fin"> Finnish </MenuItemOption>
		// 			<MenuItemOption value="fra"> French </MenuItemOption>
		// 			<MenuItemOption value="frk"> Frankish </MenuItemOption>
		// 			<MenuItemOption value="frm"> French (Old) </MenuItemOption>
		// 			<MenuItemOption value="glg"> Galician </MenuItemOption>
		// 			<MenuItemOption value="grc"> Ancient Greek </MenuItemOption>
		// 			<MenuItemOption value="heb"> Hebrew </MenuItemOption>
		// 			<MenuItemOption value="hin"> Hindi </MenuItemOption>
		// 			<MenuItemOption value="hrv"> Croatian </MenuItemOption>
		// 			<MenuItemOption value="hun"> Hungarian </MenuItemOption>
		// 			<MenuItemOption value="ind"> Indonesian </MenuItemOption>
		// 			<MenuItemOption value="isl"> Icelandic </MenuItemOption>
		// 			<MenuItemOption value="ita"> Italian </MenuItemOption>
		// 			<MenuItemOption value="ita_old"> Italian (Old) </MenuItemOption>
		// 			<MenuItemOption value="jpn"> Japanese </MenuItemOption>
		// 			<MenuItemOption value="kan"> Kannada </MenuItemOption>
		// 			<MenuItemOption value="kor"> Korean </MenuItemOption>
		// 			<MenuItemOption value="lav"> Latvian </MenuItemOption>
		// 			<MenuItemOption value="lit"> Lithuanian </MenuItemOption>
		// 			<MenuItemOption value="mal"> Malayalam </MenuItemOption>
		// 			<MenuItemOption value="mkd"> Macedonian </MenuItemOption>
		// 			<MenuItemOption value="mlt"> Maltese </MenuItemOption>
		// 			<MenuItemOption value="msa"> Malay </MenuItemOption>
		// 			<MenuItemOption value="nld"> Dutch </MenuItemOption>
		// 			<MenuItemOption value="nor"> Norwegian </MenuItemOption>
		// 			<MenuItemOption value="pol"> Polish </MenuItemOption>
		// 			<MenuItemOption value="por"> Portuguese </MenuItemOption>
		// 			<MenuItemOption value="ron"> Romanian </MenuItemOption>
		// 			<MenuItemOption value="rus"> Russian </MenuItemOption>
		// 			<MenuItemOption value="slk"> Slovakian </MenuItemOption>
		// 			<MenuItemOption value="slv"> Slovenian </MenuItemOption>
		// 			<MenuItemOption value="spa"> Spanish </MenuItemOption>
		// 			<MenuItemOption value="spa_old"> Old Spanish </MenuItemOption>
		// 			<MenuItemOption value="sqi"> Albanian </MenuItemOption>
		// 			<MenuItemOption value="srp"> Serbian (Latin) </MenuItemOption>
		// 			<MenuItemOption value="swa"> Swahili </MenuItemOption>
		// 			<MenuItemOption value="swe"> Swedish </MenuItemOption>
		// 			<MenuItemOption value="tam"> Tamil </MenuItemOption>
		// 			<MenuItemOption value="tel"> Telugu </MenuItemOption>
		// 			<MenuItemOption value="tgl"> Tagalog </MenuItemOption>
		// 			<MenuItemOption value="tha"> Thai </MenuItemOption>
		// 			<MenuItemOption value="tur"> Turkish </MenuItemOption>
		// 			<MenuItemOption value="ukr"> Ukrainian </MenuItemOption>
		// 			<MenuItemOption value="vie"> Vietnamese </MenuItemOption>
		// 		</MenuOptionGroup>
		// 	</MenuList>
		// </Menu>
	);
};

export default LanguageDropdown;
