import React from "react";

interface Props {
	id: string;
	label: string;
	value: boolean;
	setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const Switch: React.FC<Props> = ({ id, label, value, setValue }) => {
	return (
		// <FormControl display="flex" alignItems="center" justifyContent={["flex-end", "flex-start", "flex-start"]}>
		//   <FormLabel
		//     fontSize="md"
		//     htmlFor={id}
		//     mb="0"
		//     textAlign="left"
		//     w={["auto", "100%", "100%"]}
		//   >
		//     {label}
		//   </FormLabel>
		//   <ChakraSwitch
		//     id={id}
		//     isChecked={value}
		//     onChange={() => setValue((prev) => !prev)}
		//   />
		// </FormControl>

		<div className="form-control ">
			<label
				htmlFor={id}
				className="cursor-pointer label">
				<p
					className="mr-3"
					style={{ fontFamily: "Virgil" }}>
					{label}
				</p>
				<input
					type="checkbox"
					className="toggle toggle-primary"
					id={id}
					checked={value}
					onChange={() => setValue((prev) => !prev)}
				/>
			</label>
		</div>

		// <div className="flex items-center justify-end md:justify-start">
		// 	<label
		// 		htmlFor={id}
		// 		className="text-md mb-0 text-left w-auto md:w-full">
		// 		{label}
		// 	</label>

		// 	<input
		// 		type="checkbox"
		// 		id={id}
		// 		checked={value}
		// 		onChange={() => setValue((prev) => !prev)}
		// 		className="form-checkbox h-5 w-5 text-blue-600"
		// 	/>
		// </div>
	);
};

export default Switch;
