import React, { createContext, useContext } from "react";

type Context = {
  blurText: boolean;
  blurFace: boolean;
  setBlurText: React.Dispatch<React.SetStateAction<boolean>>;
  setBlurFace: React.Dispatch<React.SetStateAction<boolean>>;
};

const ToggleSwitchContext = createContext<Context>({
  blurText: false,
  blurFace: false,
  setBlurText: () => {},
  setBlurFace: () => {},
});
export const useToggleSwitchContext = () => useContext(ToggleSwitchContext);

export default ToggleSwitchContext;
