import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { v4 } from "uuid";
import { useMediaQuery } from "../UI/components/Features";
import { useCanvasDataContext } from "../util/CanvasDataContext";

interface Props {
	setImageUrl: React.Dispatch<React.SetStateAction<string>>;
	setDefaultType: React.Dispatch<React.SetStateAction<string>>;
	setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const UploadButton: React.FC<Props> = ({
	setImageUrl,
	setDefaultType,
	setFile,
}) => {
	const fileInput = useRef<HTMLInputElement>(null);
	const dropRef = useRef<HTMLDivElement>(null); // Ref for the drop zone
	const id = v4();
	const isLargerThanMd = useMediaQuery("(min-width: 768px)");
	const { canvasObjects } = useCanvasDataContext();
	const handleUpload = (file: File) => {
		console.log("uploading");
		setDefaultType(file.type);
		setImageUrl(URL.createObjectURL(file));
		setFile(file);
	};
	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.dataTransfer.items) {
			for (let i = 0; i < e.dataTransfer.items.length; i++) {
				if (e.dataTransfer.items[i].kind === "file") {
					const file = e.dataTransfer.items[i].getAsFile();
					if (file) {
						handleUpload(file);
						break;
					}
				}
			}
		}
	};

	return (
		<>
			<input
				type="file"
				accept="image/*"
				ref={fileInput}
				className="hidden p-36 "
				onChange={(e) => {
					if (e.target.files && e.target.files[0]) {
						handleUpload(e.target.files[0]);
					}
				}}
				key={id}
			/>
			<div
				className="w-full flex justify-evenly items-center mt-20"
				ref={dropRef}
				onDragOver={(e) => e.preventDefault()}
				onDragEnter={(e) => e.preventDefault()}
				onDrop={onDrop}>
				{isLargerThanMd ? (
					<button
						onClick={() => {
							if (!fileInput.current) return;
							fileInput.current.click();
						}}
						className={`flex flex-col bg-blue-500 text-white w-[30vw] h-[50vh] justify-center items-center alert alert-success mb-2 inline-block whitespace-nowrap`}>
						<p style={{ fontFamily: "Virgil" }}>
							Drag 'n' Drop or Upload Image
						</p>
						<p style={{ fontFamily: "Virgil" }}>and</p>
						<p style={{ fontFamily: "Virgil" }}>Blur Things</p>
					</button>
				) : (
					<button
						onClick={() => {
							if (!fileInput.current) return;
							fileInput.current.click();
						}}
						className="w-auto bg-blue-500 text-white justify-center items-center alert alert-success inline-block whitespace">
						Upload an image
					</button>
				)}
			</div>
		</>
	);
};

export default UploadButton;

// const UploadButton: React.FC<Props> = ({
// 	setImageUrl,
// 	setDefaultType,
// 	setFile,
// }) => {
// 	const fileInput = useRef<HTMLInputElement>(null);
// 	const id = v4();

// 	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
// 		console.log("uploading");
// 		if (e.target.files && e.target.files[0]) {
// 			const file = e.target.files[0];
// 			setDefaultType(file.type);
// 			setImageUrl(URL.createObjectURL(file));
// 			setFile(file);
// 		}
// 	};

// 	return (
// 		<>
// 			<input
// 				type="file"
// 				accept="image/*"
// 				ref={fileInput}
// 				style={{ display: "none" }}
// 				onChange={handleUpload}
// 				key={id}
// 			/>
// 			<Flex
// 				w="100%"
// 				justify="space-evenly"
// 				align="center">
// 				<Button
// 					leftIcon={<FontAwesomeIcon icon={faFileUpload} />}
// 					onClick={() => {
// 						if (!fileInput.current) return;
// 						fileInput.current.click();
// 					}}
// 					colorScheme="#3671ca"
// 					height="30vw"
// 					width="40vw">
// 					Drag 'n' Drop or Upload Image and Blur Things
// 				</Button>
// 			</Flex>
// 		</>
// 	);
// };

// export default UploadButton;
