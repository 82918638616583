import React, {
	useEffect,
	useRef,
	useState,
	useContext,
	useCallback,
} from "react";
import Tesseract from "tesseract.js";
import { blurFn, fitImageOn, loadModels } from "../util/helpers";
import BlurOptions from "./BlurOptions";
import DownloadImage from "./DownloadImage";
import UploadButton from "./UploadButton";
import * as faceapi from "face-api.js";
import Canvas from "./Canvas";
import LanguageDropdown from "./LanguageDropdown";
import { useMediaQuery } from "../UI/components/Features";
import Header from "../UI/components/Header";
import Features from "../UI/components/Features";
import ButtonUpload from "./ButtonUpload";
import ToggleSwitchContext from "../util/ToggleSwitchContext";
import { useCanvasDataContext } from "../util/CanvasDataContext";
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { MoonLoader } from "react-spinners";

const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

export const Main: React.FC = () => {
	const placeholderImg = "/placeholder.jpg";
	const [imageUrl, setImageUrl] = useState(placeholderImg);
	const [file, setFile] = useState<File>();
	const [defaultType, setDefaultType] = useState("image/jpg");
	const [words, setWords] = useState<any>([]);
	const [faceBox, setFaceBox] = useState<any>();
	const [recognizingText, setRecognizingText] = useState(false);
	const [modelsLoaded, setModelsLoaded] = useState(false);
	const [language, setLanguage] = useState("eng");
	const [isImageUploaded, setImageUploaded] = useState(false);
	const { blurFace, blurText } = useContext(ToggleSwitchContext);

	const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasRef2 = useRef<HTMLCanvasElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const isMounted = useRef(false);
	const isLargerThanMd = useMediaQuery("(min-width: 768px)");
	const { canvasObjects } = useCanvasDataContext();

	const ctxLoader = useCallback(() => {
		const canvas = canvasRef.current;
		const image = imageRef.current;
		const ctx = canvas?.getContext("2d", { willReadFrequently: true });

		if (!canvas || !ctx || !imageRef) return;

		ctxRef.current = ctx;
		ctx.clearRect(0, 0, canvas.height, canvas.width);

		canvas.height = image!.offsetHeight * 1.2;
		canvas.width = image!.offsetWidth * 1.2;

		fitImageOn(canvas, image, ctx);
		copyCanvas();
		recognizeFace().then(() => {
			if (blurFace) applyBlurEffect();
		});
		setWords(null);
	}, []);

	useEffect(() => {
		if (imageRef.current?.complete) {
			ctxLoader();
		}
	}, []);

	useEffect(() => {
		if (isMounted.current) {
			recognizeText(file || imageRef.current);
		} else {
			isMounted.current = true;
		}
	}, [file, language]);

	const recognizeText = async (file: any) => {
		if (!canvasRef.current || recognizingText) return;
		console.log("Recognizing Text...");
		setRecognizingText(true);
		const {
			data: { words },
		} = await Tesseract.recognize(file, language);

		setRecognizingText(false);
		if (words.length > 0) {
			setWords(words.map((word) => word.bbox));
		} else {
			setWords(words);
		}
	};

	useEffect(() => {
		if (isMounted.current) {
			recognizeText(file || imageRef.current);
		} else {
			isMounted.current = true;
		}
	}, [file, language]);

	const waitForRecognitionToEnd = () => {
		return new Promise((resolve) => {
			const interval = setInterval(() => {
				if (!recognizingText && !recognizeFace) {
					clearInterval(interval);
					resolve(true);
				}
			}, 10);
		});
	};

	const applyBlurEffect = async () => {
		if (!canvasRef2.current) return;
		// if (!blurFace) copyCanvas();
		await waitForRecognitionToEnd();

		if (canvasObjects && blurText && canvasRef2.current) {
			blurFn(canvasObjects, 50, canvasRef2.current, "TEXT");
		}

		if (canvasObjects && blurFace && canvasRef2.current) {
			blurFn(canvasObjects, 50, canvasRef2.current, "FACE");
		}
	};

	const copyCanvas = () => {
		setImageUploaded(true);
		if (!canvasRef.current || !canvasRef2.current) return;
		const canvas1 = canvasRef.current;
		const canvas2 = canvasRef2.current;
		const ctx2 = canvas2.getContext("2d", { willReadFrequently: true });

		if (!ctx2) return;

		ctx2.clearRect(0, 0, canvas2.height, canvas2.width);

		canvas2.height = canvas1.height;
		canvas2.width = canvas1.width;

		ctx2.drawImage(canvas1, 0, 0);
	};

	const recognizeFace = async () => {
		if (!modelsLoaded) {
			await loadModels(setModelsLoaded);
		}
		const data = await faceapi
			.detectAllFaces(canvasRef.current!)
			.withFaceLandmarks();

		setFaceBox(data.map((face) => face.detection.box));
	};

	return (
		<div className=" w-full">
			<div
				className={`flex items-center ${
					isLargerThanMd ? "justify-evenly" : "justify-start"
				} min-h-[80vh]`}>
				{!canvasRef2.current && (
					<div
						className={`flex ${
							isLargerThanMd ? "flex-row" : "flex-col"
						} items-center justify-center w-[100vw] md:w-3/5 ${
							!isLargerThanMd ? "-ml-1/5" : "ml-1/4"
						} gap-8`}>
						<div className="flex flex-col">
							<UploadButton
								setDefaultType={setDefaultType}
								setImageUrl={setImageUrl}
								setFile={setFile}
							/>
							<BlurOptions
								canvasRef2={canvasRef2}
								copyCanvas={copyCanvas}
								recognizing={recognizingText}
							/>
						</div>
						<Features />
					</div>
				)}
				{imageUrl !== placeholderImg && (
					<div className="w-[100vw] pt-5 flex gap-5  md:ml-[12%]">
						<div
							className={`flex flex-col  md:flex-row ${
								isLargerThanMd ? "" : "flex-col-reverse"
							}`}>
							<div className="flex flex-col items-center mb-10">
								<img
									className="max-w-[60vh] max-h-[40vh] mb-2 border-4 border-double border-gray-300 rounded-lg shadow-sm"
									src={imageUrl}
									ref={imageRef}
									onLoad={ctxLoader}
									alt="something"
								/>
								<h3 className="text-xl text-center font-medium mb-2 text-purple-100">
									Your Image
								</h3>
								<BlurOptions
									canvasRef2={canvasRef2}
									copyCanvas={copyCanvas}
									recognizing={recognizingText}
								/>
								<ButtonUpload
									setDefaultType={setDefaultType}
									setImageUrl={setImageUrl}
									setFile={setFile}
								/>
							</div>
							<div className="relative">
								<canvas
									className="m-auto hidden border-4 border-gray-300 max-w-[70vh] max-h-[50vh]"
									ref={canvasRef}></canvas>
								<canvas
									className="m-auto border-4 border-gray-300 rounded-md max-w-[70vh] max-h-[50vh]"
									ref={canvasRef2}></canvas>
								<Canvas
									height={canvasRef.current?.height!}
									width={canvasRef.current?.width!}
									words={words}
									faceBox={faceBox}
								/>
								{recognizingText && (
									<>
										<div className="opacity-30 absolute inset-0"></div>
										<div className="flex flex-col items-center absolute top-1/2  transform -translate-x-1/2 -translate-y-1/2">
											{/* <Spinner /> */}
											{/* <ClipLoadertransform -translate-x-1/2 -translate-y-1/2
												color={"#36d7b7"}
												loading={recognizingText}
												cssOverride={override}
												size={150}
												aria-label="Loading Spinner"
												data-testid="loader"
											/> */}
											<MoonLoader color="white" />
											<p>Recognizing...</p>
										</div>
										<h3 className="text-xl text-center font-medium my-2">
											Blurred Image
										</h3>
									</>
								)}
								<div className="flex flex-col p-1.5">
									<div className="w-full">
										<LanguageDropdown
											language={language}
											setLanguage={setLanguage}
										/>
									</div>
									<DownloadImage
										defaultType={defaultType}
										imageUrl={imageUrl}
										canvas={canvasRef2.current!}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Main;
