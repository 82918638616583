import "./App.css";
import { useState } from "react";
import CanvasDataContext from "./util/CanvasDataContext";
import ToggleSwitchContext from "./util/ToggleSwitchContext";
import { Footer } from "./UI/components/Footer";
import { Main } from "./components/Main";
import Header from "./UI/components/Header";

function App() {
	const [canvasObjects, setCanvasObjects] = useState<fabric.Object[]>([]);
	const [blurText, setBlurText] = useState(false);
	const [blurFace, setBlurFace] = useState(false);

	const backgroundStyles = {
		backgroundImage: `url(${process.env.PUBLIC_URL}/title.svg)`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	};

	return (
		<div
			className="App"
			style={backgroundStyles}>
			<CanvasDataContext.Provider value={{ canvasObjects, setCanvasObjects }}>
				<ToggleSwitchContext.Provider
					value={{ blurText, blurFace, setBlurFace, setBlurText }}>
					<Header />
					<Main />
					<Footer />
				</ToggleSwitchContext.Provider>
			</CanvasDataContext.Provider>
		</div>
	);
}

export default App;
