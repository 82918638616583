import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { v4 } from "uuid";
import { useMediaQuery } from "../UI/components/Features";
import { useCanvasDataContext } from "../util/CanvasDataContext";

interface Props {
	setImageUrl: React.Dispatch<React.SetStateAction<string>>;
	setDefaultType: React.Dispatch<React.SetStateAction<string>>;
	setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const ButtonUpload: React.FC<Props> = ({
	setImageUrl,
	setDefaultType,
	setFile,
}) => {
	const fileInput = useRef<HTMLInputElement>(null);
	const dropRef = useRef<HTMLDivElement>(null); // Ref for the drop zone
	const id = v4();
	const isLargerThanMd = useMediaQuery("(min-width: 768px)");
	const { canvasObjects } = useCanvasDataContext();
	const handleUpload = (file: File) => {
		console.log("uploading");
		setDefaultType(file.type);
		setImageUrl(URL.createObjectURL(file));
		setFile(file);
	};
	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.dataTransfer.items) {
			for (let i = 0; i < e.dataTransfer.items.length; i++) {
				if (e.dataTransfer.items[i].kind === "file") {
					const file = e.dataTransfer.items[i].getAsFile();
					if (file) {
						handleUpload(file);
						break;
					}
				}
			}
		}
	};

	return (
		<>
			<input
				type="file"
				accept="image/*"
				ref={fileInput}
				className="hidden p-36"
				onChange={(e) => {
					if (e.target.files && e.target.files[0]) {
						handleUpload(e.target.files[0]);
					}
				}}
				key={id}
			/>
			<div
				className="w-full flex justify-evenly items-center mt-20"
				ref={dropRef}
				onDragOver={(e) => e.preventDefault()}
				onDragEnter={(e) => e.preventDefault()}
				onDrop={onDrop}>
				{/* <button
					onClick={() => {
						if (!fileInput.current) return;
						fileInput.current.click();
					}}
					className="flex flex-col bg-blue-500 text-white justify-center items-center">
					
				</button> */}
				<button
					className={` w-auto bg-blue-500 text-white justify-center items-center alert alert-success inline-block whitespace`}
					onClick={() => {
						if (!fileInput.current) return;
						fileInput.current.click();
					}}>
					Upload Another Image
				</button>
			</div>
		</>
	);
};

export default ButtonUpload;
