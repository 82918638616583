// import { Button } from "@chakra-ui/button";
// import { Checkbox } from "@chakra-ui/checkbox";
// import { useDisclosure } from "@chakra-ui/hooks";
// import { Input } from "@chakra-ui/input";
// import { Box, Stack } from "@chakra-ui/layout";
// import {
//   Modal,
//   ModalBody,
//   ModalContent,
//   ModalHeader,
//   ModalOverlay,
// } from "@chakra-ui/modal";
// import { useToast } from "@chakra-ui/toast";
import React, { useRef, useState } from "react";

interface Props {}

// const EmailModal = () => {
// const { isOpen, onOpen, onClose } = useDisclosure();
// const emailRef = useRef<HTMLInputElement>(null);
// const [checkedItems, setCheckedItems] = React.useState([true, false, false]);
// const toast = useToast();

// const handleSubmit = async (e: any) => {
//   e.preventDefault();
//   const data = {
//     email: emailRef.current?.value,
//     checkedItems,
//   };

//   const response = await fetch("/api/post", {
//     method: "POST",
//     body: JSON.stringify(data),
//     headers: { "Content-type": "application/json" },
//     // mode: "cors",
//   });

//   if (response.ok) {
//     toast({
//       status: "success",
//       title: "Success",
//       description: "We'll notify you once we release new models",
//       duration: 2000,
//       isClosable: true,
//     });
//   } else {
//     toast({
//       status: "error",
//       title: "Something went wrong",
//       description: "Sorry, an unexpected error occured",
//       duration: 2000,
//       isClosable: true,
//     });
//   }
//   onClose();
// };

// return (
// <></>
// <>
//   <Button
//     my="2"
//     mr="1"
//     onClick={onOpen}
//     variant="solid"
//     colorScheme="orange"
//   >
//     I want better models
//   </Button>

//   <Modal isOpen={isOpen} onClose={onClose} isCentered>
//     <ModalOverlay />
//     <ModalContent>
//       <ModalHeader>
//         Signup for a notification when we launch better models:
//       </ModalHeader>
//       <ModalBody>
//         <Box as="form" onSubmit={handleSubmit}>
//           <Input
//             type="email"
//             required
//             ref={emailRef}
//             placeholder="Your Email"
//             mt="2"
//           />
//           <Stack mt="4">
//             <Checkbox
//               defaultChecked
//               onChange={(e) =>
//                 setCheckedItems([
//                   e.target.checked,
//                   checkedItems[1],
//                   checkedItems[2],
//                 ])
//               }
//             >
//               I'm interested in text blurring!
//             </Checkbox>
//             <Checkbox
//               onChange={(e) =>
//                 setCheckedItems([
//                   checkedItems[0],
//                   e.target.checked,
//                   checkedItems[2],
//                 ])
//               }
//             >
//               I'm interested in face blurring!
//             </Checkbox>
//             <Checkbox
//               onChange={(e) =>
//                 setCheckedItems([
//                   checkedItems[0],
//                   checkedItems[1],
//                   e.target.checked,
//                 ])
//               }
//             >
//               I'm interested in background blurring!
//             </Checkbox>
//           </Stack>
//           <Box
//             mt="8"
//             display="flex"
//             alignItems="center"
//             justifyContent="flex-end"
//           >
//             <Button mr={3} onClick={onClose}>
//               Close
//             </Button>
//             <Button type="submit" colorScheme="green">
//               Submit
//             </Button>
//           </Box>
//         </Box>
//       </ModalBody>
//     </ModalContent>
//   </Modal>
// </>
// 	);
// };

const EmailModal = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div>
			<button
				className="btn"
				onClick={openModal}>
				I want better models
			</button>
			{isModalOpen && (
				<dialog
					className="modal"
					id="my_modal_4">
					<div className="modal-box w-11/12 max-w-5xl">
						<div className="modal-action">
							<form method="dialog">
								<button
									className="btn"
									onClick={closeModal}>
									Close
								</button>
							</form>
						</div>
					</div>
				</dialog>
			)}
		</div>
	);
};

export default EmailModal;
