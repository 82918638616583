import React, { createContext, useContext } from "react";

type Context = {
  canvasObjects: fabric.Object[];
  setCanvasObjects: React.Dispatch<React.SetStateAction<fabric.Object[]>>;
};

const CanvasDataContext = createContext<Context>({
  canvasObjects: [],
  setCanvasObjects: () => {},
});
export const useCanvasDataContext = () => useContext(CanvasDataContext);

export default CanvasDataContext;
