import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { canvastoFile, downloadFile } from "image-conversion";
import EmailModal from "./EmailModal";

interface Props {
	defaultType: string;
	imageUrl: string;
	canvas: HTMLCanvasElement;
}

const DownloadImage: React.FC<Props> = ({ defaultType, canvas, imageUrl }) => {
	const [value, setValue] = React.useState("1");

	const convertAndDownload = async () => {
		let type: string = "";

		if (value === "1") {
			type = defaultType;
		} else if (value === "2") {
			type = "image/jpeg";
		} else if (value === "3") {
			type = "image/png";
		}

		// @ts-ignore
		const file = await canvastoFile(canvas, 1, type);
		downloadFile(file, "image");
		URL.revokeObjectURL(imageUrl);
	};

	return (
		<div className="flex flex-col md:flex-row items-center justify-evenly pr-2">
			<div className="flex flex-col">
				<button
					// className="bg-blue-500 text-white flex items-center"
					// className="btn btn-info"
					className={` bg-blue-500 text-white justify-center items-center alert alert-success mb-2 inline-block whitespace`}>
					<FontAwesomeIcon
						icon={faDownload}
						className="pr-2"
					/>
					Download Blurred Image
				</button>
				<EmailModal />
			</div>
			<div className="ml-2.5 mb-4">
				<div className="text-purple-200">
					<input
						type="radio"
						id="sameAsUploaded"
						name="download-type"
						value="1"
						onChange={(e) => setValue(e.target.value)}
						checked={value === "1"}
						className="mr-2"
					/>
					<label
						htmlFor="sameAsUploaded"
						className="whitespace-nowrap inline-block"
						style={{ fontFamily: "Virgil" }}>
						Same as uploaded file
					</label>
				</div>
				<div className="text-purple-200">
					<input
						type="radio"
						id="jpeg"
						name="download-type"
						value="2"
						onChange={(e) => setValue(e.target.value)}
						checked={value === "2"}
						className="mr-2"
					/>
					<label
						htmlFor="jpeg"
						style={{ fontFamily: "Virgil" }}>
						JPEG
					</label>
				</div>
				<div className="text-purple-200">
					<input
						type="radio"
						id="png"
						name="download-type"
						value="3"
						onChange={(e) => setValue(e.target.value)}
						checked={value === "3"}
						className="mr-2"
					/>
					<label
						htmlFor="png"
						style={{ fontFamily: "Virgil" }}>
						PNG
					</label>
				</div>
			</div>
		</div>
	);
};

export default DownloadImage;
